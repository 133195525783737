<template>
  <div>
    <!-- 统计信息 -->
    <div class="tongji">
      <div class="main">
        <div class="left" style="background:#2389F2">{{ $t('dd.ztz') }}</div>
        <span class="right">{{ total_money }}</span>
      </div>
      <div class="main">
        <div class="left" style="background:#FFC913">{{ $t('dd.dds') }}</div>
        <span class="right">{{ order_count }}</span>
      </div>
      <div class="main">
        <div class="left" style="background:#FF6213">
          {{ $t('dd.yingkui') }}
        </div>
        <span class="right">{{ profit }}</span>
      </div>
    </div>
    <el-form :inline="true">
      <!-- 搜索区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:200px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryinfo.start"
          type="datetime"
          :placeholder="$t('qxz')"
          align="right"
          :picker-options="pickerOptions"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryinfo.end"
          type="datetime"
          :placeholder="$t('qxz')"
          align="right"
          :picker-options="pickerOptions"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-button icon="el-icon-search" circle @click="getlist"></el-button>
    </el-form>
    <!-- 列表区域 -->
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column
        prop="orderno"
        :label="$t('home.bianma')"
      ></el-table-column>
      <el-table-column
        prop="username"
        :label="$t('home.yhm')"
      ></el-table-column>
      <el-table-column
        prop="open_price"
        :label="$t('home.gmdj')"
      ></el-table-column>
      <el-table-column prop="times" :label="$t('home.dqsj')"></el-table-column>
      <el-table-column prop="ratio" :label="$t('home.lilv')"></el-table-column>
      <el-table-column
        prop="total_price"
        :label="$t('home.gmzj')"
      ></el-table-column>
      <el-table-column
        prop="open_balance"
        :label="$t('home.gmsye')"
      ></el-table-column>
      <el-table-column
        prop="end_price"
        :label="$t('home.jssdj')"
      ></el-table-column>
      <el-table-column prop="profit" :label="$t('home.jsscsfy')">
        <template slot-scope="{ row }">
          <span v-if="parseInt(row.profit) > 0" style="color:#008000">
            {{ row.profit }}
          </span>
          <span v-else style="color:#f00">{{ row.profit }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="end_balance"
        :label="$t('home.jshye')"
      ></el-table-column>
      <el-table-column prop="status" :label="$t('home.zhuangtai')">
        <template slot-scope="{ row }">
          <span v-if="row.status === '0'">{{ $t('home.zzjx') }}</span>
          <span v-else>{{ $t('home.ywc') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" :label="$t('home.leixing')">
        <template slot-scope="{ row }">
          <span v-if="row.type === '1'">{{ $t('home.jieduo') }}</span>
          <span v-else>{{ $t('home.jiekong') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" :label="$t('home.cjsj')">
        <template slot-scope="{ row }">
          {{ row.createtime | dateformat }}
        </template>
      </el-table-column>
      <el-table-column
        prop="product_name"
        :label="$t('bizhong')"
      ></el-table-column>

      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 编辑产品分类弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span v-if="status === '0'"
        >{{ $t('home.zhuangtai') }}:{{ $t('dd.ddjxz') }}</span
      >
      <span v-else>{{ $t('home.zhuangtai') }}:{{ $t('dd.ddywc') }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editclose">{{ $t('queding') }}</el-button>
        <!-- <el-button type="primary" @click="editsubmit" :disabled="status > '0'"
          >取消改订单</el-button
        > -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 快捷时间
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      // 分页请求数据对象
      queryinfo: {
        query: '',
        start: '',
        end: '',
        page: 1
      },
      infolist: [],
      total: 0,
      loading: true,
      editdialogvisible: false,
      status: '',
      editid: '',
      // 统计信息
      order_count: 0,
      total_money: 0,
      profit: 0
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/orders/orderlist', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.total = +data.data.count
      this.order_count = data.data.o_count
      this.total_money = data.data.total
      this.profit = data.data.profit
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },

    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/orders/editorder/id/' + id)
      if (data) {
        if (data.code === 200) {
          this.status = data.data.status
          this.editid = data.data.id
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 编辑提交
    async editsubmit() {
      const { data } = await this.$http.post('/admin/orders/editorder', {
        id: this.editid
      })
      if (data) {
        if (data.code === 200) {
          this.getlist()
          this.$message.success(data.msg)
          this.editclose()
        } else {
          this.$message.error(data.msg)
        }
      }
      this.editdialogvisible = false
    },
    // 关闭编辑弹窗
    editclose() {
      this.editdialogvisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.tongji {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  .main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f5;
    margin-right: 20px;
    text-align: center;
    border-radius: 10px;
    .left {
      color: #fff;
      height: 100%;
      line-height: 60px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 0 10px;
    }
    .right {
      width: 100px;
      color: #000;
    }
  }
}
</style>
